.uppy-DashboardContent-bar,
.uppy-StatusBar.is-waiting .uppy-StatusBar-actions,
.uppy-StatusBar,
.uppy-StatusBar:not([aria-hidden='true']).is-waiting,
.uppy-ProviderBrowser-headerBar,
.uppy-ProviderBrowser-viewType--list,
.uppy-ProviderBrowser-list,
div.uppy-Dashboard-inner,
.uppy-DashboardFileCard,
.uppy-Dashboard-actions {
  background: #303030 !important;
  border: none !important;
}
.uppy-StatusBar-statusPrimary,
.uppy-StatusBar-content,
.uppy-Dashboard-dropFilesTitle,
.uppy-DashboardTab-btn,
.uppy-Provider-breadcrumbs,
.uppy-DashboardFileCard-label {
  color: #ddd !important;
}
.uppy-DashboardItem-status,
.uppy-ProviderBrowser-user {
  color: #aaa;
}
.uppy-Dashboard-AddFiles-title {
  color: #ddd;
}
.uppy-StatusBar-actionBtn,
.uppy-DashboardContent-back,
.uppy-DashboardContent-addMore,
.uppy-DashboardTab-btn:hover,
.uppy-Dashboard-browse,
.uppy-ProviderBrowser-userLogout {
  color: #00bc8c !important;
  stroke: none !important;
}

.uppy-StatusBar-actionBtn--upload {
  color: white !important;
  display: block !important;
  width: 100% !important;
  font-weight: 400 !important;
  font-size: 1.171875rem !important;
  font-family: Lato, -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.uppy-DashboardContent-panel,
.uppy-ProviderBrowser-footer,
.uppy-Dashboard-AddFilesPanel {
  background: #333 !important;
}
.uppy-c-btn-primary,
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background: #00bc8c !important;
  width: 200px !important;
  margin: auto;
}
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background: #009670 !important;
}
.uppy-DashboardItem {
  border-bottom: 1px solid #555 !important;
}

.uppy-Provider-breadcrumbs {
  display: block !important;
}
.uppy-ProviderBrowserItem,
.uppy-c-btn-link,
.uppy-DashboardItem-name a,
.uppy-ProviderBrowser-searchInput,
.uppy-Provider-breadcrumbs .uppy-u-reset {
  color: white !important;
}
.uppy-ProviderBrowser-search {
  background: #222 !important;
}

.uppy-DashboardItem-name {
  color: white;
}

.uppy-Dashboard-inner {
  margin: auto;
}

/*** carousel ****/
.carousel {
  margin: 0 35px;
  padding-bottom: 40px;
  cursor: pointer;
}
.carousel .img-fluid {
  min-height: 400px;
  object-fit: cover;
}

.carousel-control-prev {
  left: -45px;
  width: 30px;
  cursor: pointer;
}
.carousel-control-next {
  right: -45px;
  width: 30px;
}
.carousel-indicators li {
  width: 12px;
  height: 12px;
  background: none;
  border: none;
  position: relative;
  margin: 0 5px;
}
.carousel-indicators li:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  background: #fff;
}
.carousel-caption {
  bottom: 0;
}
.uppy-DashboardAddFiles {
  border: none !important;
}
