$grey: #999;

.logoutButton {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: $grey;
  opacity: 0.8;
  font-size: 0.9em;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
}

.dropdown-toggle {
  background: transparent;
  border: 0;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.userSummary {
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 10px;
  }
  img {
    width: 1.6em;
    height: auto;
    margin-right: 5px;
  }
  .tokensSummary {
    font-size: 1.6em;
  }
  .accountDropdownMenu {
    background-color: #fff;
    button {
      color: #444;
      &:hover {
        background-color: #eee;
      }
      &:active {
        background-color: #eee;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .navContainer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .userSummary {
    flex-direction: column;
    > *:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 5px;
    }
  }
}
