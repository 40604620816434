$ma-brightGreen: #00bc8c;
$ma-danger: #e74c3c;

.danger {
  color: $ma-danger;
}

.fullHeight {
  height: 100%;
}

.dashboardContainer {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jumbotron {
  margin: 0px auto 15px;
  padding: 2rem 1rem;
  height: calc(100vh - 114px);
  min-height: 700px;
  max-height: 900px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  margin-bottom: unset;

  .bottomControls {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    :nth-child(1) {
      justify-self: end;
    }
    :nth-child(2) {
      justify-self: start;
    }
  }
  @media (max-width: 832px) {
    .bottomControls {
      grid-column-gap: 10px;
    }
  }
  @media (max-width: 575px) {
    padding: 1rem 0rem;
  }
}

.wizardSteps {
  display: flex;
  justify-content: space-around;
  margin: 0px 10%;
  padding-bottom: 30px;
  font-size: 1.5em;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-bottom: 0px;
    > div {
      padding-bottom: 10px;
    }
  }
}

.bookingButton {
  padding: 10px 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.fixedWidth {
    width: 200px;
  }
  &.selected {
    background-color: $ma-brightGreen;
  }
}

.importModalCompanionText {
  padding-top: 4px;
}

.gearSelectionWrapper {
  display: grid;
  grid-template-columns: repeat(2, 0.5fr) 1fr;
  column-gap: 15px;
  row-gap: 15px;
  height: 100%;
  min-height: 305px;
  :not(:nth-child(3)) {
    margin-right: 15px;
  }
  .column {
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    background-color: #444444;
    align-self: stretch;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .rowButton {
      margin: 6px 0px;
      padding: 0.675rem 0.75rem;
      background-color: #606060;
      text-transform: capitalize;
      &.selected {
        background-color: $ma-brightGreen;
      }
    }

    .smallText {
      font-size: 0.9em;
    }

    .textLeft {
      text-align: left;
    }

    .productLink {
      color: $ma-brightGreen;
      text-decoration-line: underline;
    }
  }
  .product {
    h5 {
      text-align: center;
    }
  }
  @media (max-width: 832px) {
    margin-right: 0px;
    grid-template-columns: unset;
    grid-template-rows: repeat(2, 0.5fr) 1fr;
    :not(:nth-child(3)) {
      margin-right: 0px;
    }
  }
}

.timetable {
  .timeBlock {
    width: 12.5%;
  }
  .timeWrapper {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  @media (max-width: 1200px) {
    .timeWrapper {
      flex: 0 0 66.6666666666%;
      max-width: 66.6666666666%;
    }
  }
  @media (max-width: 767px) {
    .timeWrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .leftPlaceholder {
      display: none;
    }
  }
}
