$darkGreen: #00bc8c;
$heroRed: #6e1d0c;
$yellow: #f39c12;
$orange: #fd7e14;

.darkGreenText {
  color: $darkGreen;
}

.card {
  height: 100%;
  border: unset;

  &.active {
    border: 1px solid $darkGreen;
  }

  &.hero {
    background-color: $heroRed;

    svg {
      transform: rotate(-50deg);
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.8rem;
      color: $yellow;
      filter: drop-shadow(-5px 0px 3px #00000050);
    }

    .card-footer {
      button {
        background-color: $yellow;
        border-color: $yellow;
        &:hover {
          background-color: $orange;
          border-color: $orange;
        }
      }
    }
  }
  .card-footer {
    h5 {
      margin: 0.37em 0;
    }
  }
  .subscriptionProperty {
    margin-bottom: 1em;
  }
  .subscriptionComment {
    margin-bottom: 1em;
    font-size: 0.7em;
  }
  .card-body {
    padding: 1rem;
  }
}

.shopWrapper {
  display: flex;

  .planSummary {
    display: flex;
    flex-direction: column;
    flex: 1;
    .userPlanStatus,
    .upsellOffer {
      max-width: 70%;
      min-width: 350px;
    }
  }

  .tokenOffers {
    flex: 1;
  }

  .summaryCard {
    border: 1px solid #20c997;
    background: #444444;
    border-radius: 5px;
    padding: 20px 25px;
    min-width: 350px;
    margin: 20px 0;
  }
}

.shopCardWrapper {
  padding: 5px 5px 5px 0;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;

  &.fullSize {
    width: 100%;
    height: 100%;
  }

  .banner {
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px 20px;
    width: 100px;
    font-size: 0.5em;
    transform-origin: center;
    transform: rotate(45deg) translate(30px, -8px);

    &.orange {
      background-color: $orange;
    }
    &.yellow {
      background-color: $yellow;
    }
  }
}

.shopCard {
  background: #303030;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  height: 100%;

  // &.bigText {
  //   font-size: 24px;
  // }
  &.flex {
    display: flex;
    justify-content: space-between;
  }
  &.withBorder {
    border: 1px solid #20c997;
  }
  &.brightBackground {
    background: #444444;
  }
  &.bigPadding {
    padding: 20px 25px;
  }
  h4 {
    line-height: unset;
    &.orangeTitle {
      color: $orange;
    }
  }

  img {
    width: 2em;
  }

  .cardDescription {
    font-size: 0.8rem;
    line-height: 1.5rem;
    opacity: 0.6;
    margin-top: 20px;
  }

  .cardSupplementText {
    opacity: 0.6;
    font-size: 0.7em;
    margin-left: 0.5em;
  }

  // for plan cards
  .planPriceWrapper {
    margin: 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .planPrice {
      font-size: 1.25em;
      margin-right: 5px;
    }

    .greyText {
      margin-left: 5px;
      opacity: 0.4;
      font-size: 0.8em;
    }

    .activePlan {
      font-size: 0.9em;
      font-weight: bold;
    }
  }

  // for MAT buttons
  .priceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 15px;

    h4 {
      margin: 0;
      &.orangeTitle {
        color: $orange;
      }
      .greyText {
        margin-left: 5px;
        opacity: 0.4;
        color: #fff;
      }
    }
  }
  .saveAmount {
    font-size: 0.75em;
    color: $yellow;
  }
  .offerWrapper {
    display: flex;
    align-items: center;
  }
  .uniformWidth {
    width: 115px;
  }
}

.shopCardSummaryWrapper {
  margin: 20px 0px;
}

.planListAlignment {
  display: flex;
  justify-content: center;
  .planListWrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(180px, 493px));
    grid-gap: 8px;
  }
}

@media (max-width: 1024px) {
  .planListAlignment {
    .planListWrapper {
      grid-template-columns: repeat(2, minmax(180px, 400px));
    }
  }
  .shopCard {
    .priceWrapper {
      flex-direction: column;
      align-items: flex-start;
      h4 {
        font-size: 1.3em;
      }
    }
  }
}

@media (max-width: 768px) {
  .shopWrapper {
    flex-direction: column;
    .planSummary {
      .userPlanStatus,
      .upsellOffer {
        max-width: unset;
      }
    }
  }
  .planListAlignment {
    flex: 0 1 500px;
    .planListWrapper {
      grid-template-columns: 1fr;
    }
  }
}
